<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updatingItem.code_name }} - {{ $t("labels.license") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <LicenseTable :id-warehouse="updatingItem.id" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "License",
  components: {
    LicenseTable: () => import("@/components/warehouse/LicenseTable"),
  },
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
